import { StoryblokComponent, storyblokEditable } from '@storyblok/react/rsc'
import type { ContentWithMediaStoryblok } from '@vendure/codegen/storyblok'
import {
  ActionButton,
  GradientCard,
  PreTitle,
  RichText,
  StoryblokImage,
  TitleWithAccent,
} from '@vendure/components/shared/client'
import { cn } from '@vendure/helpers'
import { useMemo } from 'react'

interface IContentWithMedia {
  blok: ContentWithMediaStoryblok
  containerBackgroundColor?: string
}

export function ContentWithMedia(props: IContentWithMedia) {
  const blok = props.blok

  const mediaPosition = blok.mediaPosition

  const content = useMemo(() => {
    return (
      <div className="w-full">
        {blok.preTitle && (
          <PreTitle
            containerProps={{
              className: 'mb-4',
            }}
            tag={blok.preTitleTag}
          >
            {blok.preTitle}
          </PreTitle>
        )}
        <TitleWithAccent
          containerProps={{
            className: 'mb-4 section-heading',
          }}
          tag={blok.titleTag}
          title={blok.title}
        />
        <RichText
          containerProps={{ className: 'text-lg leading-8' }}
          document={blok.text}
        />
        {blok.bloks && blok.bloks.length > 0 && (
          <div className="mt-8">
            {blok.bloks?.map((item) => {
              return (
                <StoryblokComponent
                  blok={item}
                  key={item._uid}
                />
              )
            })}
          </div>
        )}
        {blok.buttons && blok.buttons.length > 0 && (
          <div className="mt-8 items-center gap-8">
            {blok.buttons?.map((item) => {
              return (
                <ActionButton
                  key={item._uid}
                  {...item}
                />
              )
            })}
          </div>
        )}
      </div>
    )
  }, [blok])

  const media = useMemo(() => {
    return (
      <>
        {blok.media?.filename && (
          <GradientCard outerProps={{ className: 'w-full' }}>
            {blok.media?.filename && (
              <StoryblokImage
                src={blok.media.filename}
                image={blok.media}
                width={800}
                height={0}
                alt={blok.media.alt ?? ''}
                className={cn(
                  'h-full w-full object-cover object-center',
                  blok.mediaPrimaryDropShadow ? 'drop-shadow-lead-xl' : '',
                )}
              />
            )}
          </GradientCard>
        )}
        {blok.mediaBloks && blok.mediaBloks.length > 0 && (
          <div className="order-1 w-full md:-order-1">
            {blok.mediaBloks.map((item) => (
              <StoryblokComponent
                blok={item}
                key={item._uid}
              />
            ))}
          </div>
        )}
      </>
    )
  }, [blok])

  return (
    <div
      className="grid grid-cols-1 items-center gap-12 md:grid-cols-2"
      {...storyblokEditable(blok)}
    >
      {mediaPosition === 'left' && (
        <>
          {media}
          {content}
        </>
      )}
      {mediaPosition === 'right' && (
        <>
          {content}
          {media}
        </>
      )}
    </div>
  )
}
