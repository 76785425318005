import { StoryblokComponent, storyblokEditable } from '@storyblok/react/rsc'
import type { PageStoryblok } from '@vendure/codegen/storyblok'

interface PageProps {
  blok: PageStoryblok
}

export function Page({ blok }: PageProps) {
  return (
    <main {...storyblokEditable(blok)}>
      {blok.blocks?.map((nestedBlok) => (
        <StoryblokComponent
          blok={nestedBlok}
          key={nestedBlok._uid}
        />
      ))}
    </main>
  )
}
