import { StoryblokComponent, storyblokEditable } from '@storyblok/react/rsc'
import classNames from 'classnames'
import type { GridStoryblok } from 'packages/codegen/storyblok/src'

interface GridProps {
  blok: GridStoryblok
}

export function Grid({ blok }: GridProps) {
  return (
    <div
      className={classNames({
        'grid grid-cols-1 gap-6': true,
        'md:grid-cols-2': blok.columns === '2',
        'md:grid-cols-3': blok.columns === '3',
        'md:grid-cols-4': blok.columns === '4',
      })}
      {...storyblokEditable(blok)}
    >
      {blok.items?.map((item) => (
        <StoryblokComponent
          blok={item}
          key={item._uid}
        />
      ))}
    </div>
  )
}
