import { storyblokEditable } from '@storyblok/react/rsc'
import type { HeadingStoryblok } from '@vendure/codegen/storyblok'
import { ComponentType } from 'react'

interface ICustomHeading {
  blok: HeadingStoryblok
}

export function CustomHeading({ blok }: ICustomHeading) {
  const HeadingTag = (blok.tag ?? 'span') as
    | keyof JSX.IntrinsicElements
    | ComponentType
  return (
    <HeadingTag
      className={'text-' + blok.size}
      id={blok.anchorId}
      {...storyblokEditable(blok)}
    >
      {blok.heading}
    </HeadingTag>
  )
}
