import { storyblokEditable } from '@storyblok/react/rsc'
import { PartnerFormStoryblok } from '@vendure/codegen/storyblok'
import {
  GradientCard,
  PartnerForm as PartnerFormComponent,
  RichText,
  TitleWithAccent,
} from '@vendure/components/shared/client'

interface PartnerFormProps {
  blok: PartnerFormStoryblok
}

export function PartnerForm({ blok }: PartnerFormProps) {
  return (
    <section
      {...storyblokEditable(blok)}
      id="partner-form"
    >
      <div className="mx-auto max-w-screen-sm">
        <GradientCard
          containerProps={{ className: 'space-y-6 p-4 md:p-8 bg-slate-900' }}
        >
          <div>
            <TitleWithAccent
              containerProps={{
                className: 'text-2xl md:text-4xl mb-2 md:max-w-[70%]',
              }}
              title={blok.title}
            ></TitleWithAccent>
            <RichText document={blok.content}></RichText>
          </div>
          <PartnerFormComponent></PartnerFormComponent>
        </GradientCard>
      </div>
    </section>
  )
}
