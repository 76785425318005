import { StoryblokComponent, storyblokEditable } from '@storyblok/react/rsc'
import type { FeatureGridStoryblok } from '@vendure/codegen/storyblok'
import {
  ActionButton,
  CustomLink,
  GradientCard,
  RichText,
  StoryblokImage,
} from '@vendure/components/shared/client'
import { MagicCard } from '@vendure/components/ui/magicui/magic-card'
import { cn } from '@vendure/helpers'
import classNames from 'classnames'
import { ComponentType } from 'react'

interface IFeatureGrid {
  blok: FeatureGridStoryblok
  containerBackgroundColor?: string
}

export function FeatureGrid({ blok, containerBackgroundColor }: IFeatureGrid) {
  const primaryColorFilter =
    'invert(59%) sepia(44%) saturate(3415%) hue-rotate(170deg) brightness(106%) contrast(105%)'

  return (
    <div
      className={classNames({
        'grid grid-cols-1 gap-5': true,
        'md:grid-cols-2': blok.columns === '2',
        'md:grid-cols-3': blok.columns === '3',
        'md:grid-cols-4': blok.columns === '4',
      })}
      {...storyblokEditable(blok)}
    >
      {blok.items?.map((item, i) => {
        const hasSubContent = (item.subContent?.length ?? 0) > 0
        const hasText = !!item.text?.content?.at(0)?.content
        const hasLink =
          item.target !== undefined && item.target.cached_url !== ''

        const HeadingTag = (blok.titleTag ?? 'span') as
          | keyof JSX.IntrinsicElements
          | ComponentType

        return (
          <GradientCard
            as={hasLink ? CustomLink : 'div'}
            containerProps={{
              className: cn({
                'h-full': true,
              }),
            }}
            key={item._uid}
            {...(hasLink
              ? {
                  href:
                    item.target?.linktype === 'story'
                      ? `/${item.target.cached_url!}`
                      : item.target?.cached_url,
                }
              : {})}
            {...storyblokEditable(item)}
          >
            <MagicCard
              className="p-8"
              gradientColor={'var(--tw-color-card)'}
            >
              {item.icon?.filename && (
                <div className="mb-10">
                  <StoryblokImage
                    width={400}
                    height={400}
                    alt={item.icon.filename}
                    src={item.icon.filename}
                    className="w-100 h-auto max-h-[60px] max-w-[150px] object-contain object-left"
                    style={{
                      filter: `brightness(0) invert(1) ${primaryColorFilter}`,
                    }}
                  />
                </div>
              )}
              <HeadingTag className="text-lg font-semibold text-white">
                {item.title}
              </HeadingTag>
              {hasText && (
                <RichText
                  containerProps={{ className: 'mt-2' }}
                  document={item.text}
                />
              )}
              {(item.buttons?.length ?? 0) > 0 && (
                <div className="mt-4 flex-col gap-2 md:flex-row">
                  {item.buttons?.map((item) => {
                    return (
                      <ActionButton
                        {...item}
                        key={item._uid}
                      />
                    )
                  })}
                </div>
              )}
              {hasSubContent && (
                <div className="mt-4">
                  {item.subContent?.map((item) => {
                    return (
                      <StoryblokComponent
                        blok={item}
                        key={item._uid}
                      />
                    )
                  })}
                </div>
              )}
            </MagicCard>
          </GradientCard>
        )
      })}
    </div>
  )
}
