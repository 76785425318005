import { storyblokEditable } from '@storyblok/react/rsc'
import {
  ConfSponsorsStoryblok,
  ConfSponsorStoryblok,
} from '@vendure/codegen/storyblok'
import {
  CustomLink,
  Heading,
  StoryblokImage,
} from '@vendure/components/shared/client'

function SponsorList({ items }: { items: ConfSponsorStoryblok[] }) {
  return (
    <div className="flex items-center justify-center space-x-2">
      {items.map((item) => {
        return (
          <CustomLink
            href={item.website?.cached_url ?? ''}
            key={item._uid}
            target="_blank"
            className="opacity-70 transition-opacity hover:opacity-100"
          >
            {item.logo?.filename && (
              <StoryblokImage
                width={300}
                height={0}
                image={item.logo}
                src={item.logo?.filename}
                alt={item.name ?? ''}
                className={
                  'h-16 w-[240px] object-contain object-center brightness-0 invert'
                }
              />
            )}
          </CustomLink>
        )
      })}
    </div>
  )
}

export function ConfSponsors({ blok }: { blok: ConfSponsorsStoryblok }) {
  const sponsors = [
    {
      title: 'Platinum',
      sponsors: blok.platinum,
    },
    {
      title: 'Gold',
      sponsors: blok.gold,
    },
    {
      title: 'Silver',
      sponsors: blok.silver,
    },
    {
      title: 'Bronze',
      sponsors: blok.bronze,
    },
  ]

  return (
    <div
      className="flex flex-col items-center space-y-10"
      {...storyblokEditable(blok)}
    >
      {sponsors
        .filter(
          (sponsors) => sponsors.sponsors && sponsors.sponsors?.length > 0,
        )
        .map((sponsor) => {
          return (
            <div
              key={sponsor.title}
              className="flex flex-col items-center space-y-2"
            >
              <Heading className="text-center text-2xl">
                {sponsor.title}
              </Heading>
              <SponsorList items={sponsor.sponsors ?? []} />
            </div>
          )
        })}
    </div>
  )
}
