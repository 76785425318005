import {
  PricingTableItemStoryblok,
  PricingTableStoryblok,
} from '@vendure/codegen/storyblok'
import {
  AccentFont,
  GradientCard,
  Heading,
} from '@vendure/components/shared/client'
import { Button } from '@vendure/components/ui'
import { getPrefixedLink } from '@vendure/storyblok-helpers'
import Link from 'next/link'
import { useMemo } from 'react'
import { FaCheck } from 'react-icons/fa6'
import { RxCross1 } from 'react-icons/rx'

export function PricingTableItem({
  item,
}: {
  item: PricingTableItemStoryblok
}) {
  const includedFeatures = useMemo(() => {
    return item.includedFeatures
      ? item.includedFeatures.split('\n').map((f) => f.trim())
      : undefined
  }, [item.includedFeatures])
  const excludedFeatures = useMemo(() => {
    return item.excludedFeatures
      ? item.excludedFeatures?.split('\n').map((f) => f.trim())
      : undefined
  }, [item.excludedFeatures])
  const cta = item.callToAction?.at(0)
  const isFree = item.price == '0'

  return (
    <GradientCard
      containerProps={{
        className:
          'bg-slate-900 p-6 flex flex-col justify-between items-stretch',
      }}
    >
      <div>
        <p className="text-sm text-white/60">{item.subTitle}</p>
        <Heading
          as="h3"
          className="mb-2 mt-4 text-2xl"
        >
          {item.name}
        </Heading>
        <p className="text-lg">{item.shortDescription}</p>
        <hr className="my-6 border-white/10" />
        {isFree && (
          <AccentFont className="text-4xl font-semibold">Free</AccentFont>
        )}
        {item.price == 'custom' && (
          <span className="text-4xl font-semibold text-white">Custom</span>
        )}
        {!isFree && item.price != 'custom' && (
          <span className="text-4xl font-semibold text-white">
            {Number(item.price)} EUR
            <span className="ml-2 text-lg text-white/60">/mo</span>
          </span>
        )}
        <ul className="mt-6 space-y-2">
          {includedFeatures?.map((f) => {
            return (
              <li
                key={f}
                className="flex items-center justify-start"
              >
                <FaCheck className="text-primary-400 mr-2" /> {f}
              </li>
            )
          })}
          {excludedFeatures?.map((f) => {
            return (
              <li
                key={f}
                className="flex items-center justify-start"
              >
                <RxCross1 className="mr-2 text-white" /> {f}
              </li>
            )
          })}
        </ul>
      </div>
      {cta && (
        <Button
          className="mt-10 w-full rounded-full"
          variant={isFree ? 'lead' : 'secondary'}
          asChild
        >
          <Link href={getPrefixedLink(cta.target)}>{cta.text}</Link>
        </Button>
      )}
    </GradientCard>
  )
}

export function PricingTable({ blok }: { blok: PricingTableStoryblok }) {
  return (
    <div className="grid grid-cols-1 gap-10 md:grid-cols-3">
      {blok.items?.map((item) => {
        return (
          <PricingTableItem
            item={item}
            key={item._uid}
          ></PricingTableItem>
        )
      })}
    </div>
  )
}
