import { storyblokEditable } from '@storyblok/react/rsc'
import type { SectionHeaderStoryblok } from '@vendure/codegen/storyblok'
import {
  PreTitle,
  RichText,
  TitleWithAccent,
} from '@vendure/components/shared/client'
import { cn } from '@vendure/helpers'
import { useMemo } from 'react'

interface ISectionHeader {
  blok: SectionHeaderStoryblok
  containerBackgroundColor?: string
  containerHorizontallyCentered?: boolean
}

export function SectionHeader({
  blok,
  containerHorizontallyCentered,
}: ISectionHeader) {
  const hasButtons = (blok.actionButtonsRight?.length ?? 0) > 0

  const titleAndText = useMemo(() => {
    return (
      <>
        {blok.preTitle && (
          <PreTitle
            tag={blok.preTitleTag as any}
            button={hasButtons ? blok.actionButtonsRight?.at(0) : undefined}
          >
            {blok.preTitle}
          </PreTitle>
        )}
        <TitleWithAccent
          containerProps={{
            className: 'section-heading leading-none',
          }}
          tag={blok.titleTag}
          title={blok.title}
        />
      </>
    )
  }, [blok])

  const title = useMemo(
    () => (
      <div
        className={cn({
          'max-w-full space-y-4 md:max-w-screen-md': true,
          'flex flex-col items-start justify-start md:items-center':
            !containerHorizontallyCentered,
          'mx-auto flex flex-col items-center justify-center text-center md:items-center':
            containerHorizontallyCentered,
        })}
      >
        {titleAndText}
      </div>
    ),
    [titleAndText],
  )

  return (
    <div
      className={cn({
        'mb-8 space-y-6': true,
        'w-full': !containerHorizontallyCentered,
      })}
      {...storyblokEditable(blok)}
    >
      {title}
      {blok.text && (
        <RichText
          containerProps={{
            className: cn({
              'mx-auto text-center': containerHorizontallyCentered,
            }),
          }}
          document={blok.text}
        />
      )}
    </div>
  )
}
